/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ModelOnWork } from '@/store/modelOnWork/types';
import { getLocalStorageService } from '@/services/storage.service'

const namespace: string = 'modelOnWork';

export default class ModelAtWorkInModeLivePage extends Vue {
	@State('modelOnWork') modelOnWork: ModelOnWork | undefined;
	@Getter('getMessageFromChat', {namespace}) getMessageFromChat: any;
	@Getter('getSelectedAction', {namespace}) getSelectedAction: any;
	@Action('storeChatMessage', {namespace}) storeChatMessage: any;
	@Action('fetchChatMessage', {namespace}) fetchChatMessage: any;
	@Action('storeActionModel', {namespace}) storeActionModel: any;
	@Action('startWebCamShow', {namespace}) startWebCamShow: any;
	@Mutation('setAction', {namespace}) setAction: any;

	modelChat: any = {
		chatMessage: {
			message: ''
		}
	}

	modelAtWorkInput: Array<any> = [];

	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			// this.storeModelViewingPeep({
			// 	peep: true
			// });
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			// this.storeModelViewingPerMinOrAll({
			// 	perMinutes: true
			// })
			break;
		case 'allShow':
			// this.storeModelViewingPerMinOrAll({
			// 	allShow: true
			// })
			break;
		default:
			break;
		}
	}

	sendChatMessage() {
		const name = getLocalStorageService('login');
		this.storeChatMessage({
			username: name,
			message: this.modelChat.chatMessage.message
		});
		this.modelChat.chatMessage.message = '';
	}
	onActionModel() {
		this.setAction(this.modelAtWorkInput);
		this.storeActionModel(this.modelAtWorkInput);
	}
	requestToPrivate() {
		this.$router.push({ name: 'model-at-work-in-mode-webcam' }).then(r => r);
	}
	onStartWebCamShow() {
		this.startWebCamShow();
	}
	created() {
		this.fetchChatMessage();
	}
}
